$breakpoints: (
  phoneSmall: 375px,
  phone: 450px,
  tablet: 768px,
  desktop: 960px,
  widescreen: 1152px,
  highdef: 1344px,
  ultrahd: 1920px,

);

$gameTileWidth: 178px;
$gameTileHeight: 100px;
$gameTileGap: 8px;

@import '@/styles/_globals';

.LeaderboardTable {
  transition: opacity 120ms ease;

  &--isLoading {
    pointer-events: none;
    opacity: 0.5;
  }
}

.LeaderboardTable__UserBlock {

  p {
    font-size: 80%;

    + p {
      margin-top: $spacing * 0.25;
    }
  }
}