$breakpoints: (
  phoneSmall: 375px,
  phone: 450px,
  tablet: 768px,
  desktop: 960px,
  widescreen: 1152px,
  highdef: 1344px,
  ultrahd: 1920px,

);

$gameTileWidth: 178px;
$gameTileHeight: 100px;
$gameTileGap: 8px;

@import '@/styles/_globals';

.ClientError {
  h6, p {
    text-align: center;
    font-size: 80%;
  }

  h6 {
    padding: 1rem;
  }

  p {
    margin-top: -0.5rem;
  }

  .Icon {
    cursor: pointer;
  }

  pre {
    border: solid 1px;
    font-size: 80%;
    padding: 0.5rem;
    border-radius: $radius;
    overflow: auto;
    max-height: 300px;
  }
}