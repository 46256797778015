$breakpoints: (
  phoneSmall: 375px,
  phone: 450px,
  tablet: 768px,
  desktop: 960px,
  widescreen: 1152px,
  highdef: 1344px,
  ultrahd: 1920px,

);

$gameTileWidth: 178px;
$gameTileHeight: 100px;
$gameTileGap: 8px;

@import '@/styles/_globals';

.Table {
  font-size: 80%;
  width: 100%;

  thead {
    font-weight: bold;
    text-align: left;
  }
  
  th,
  td {
    padding: ($spacing * 0.25) ($spacing * 0.5);
    vertical-align: middle;

    .Input,
    .Checkbox {
      display: inline-block;
    }
  }

  th {
    &:first-child {
      border-top-left-radius: $controlRadius;
    }
    &:last-child {
      border-top-right-radius: $controlRadius;
    }
  }

  tbody {
    tr:last-child {
      td {
        &:first-child {
          border-bottom-left-radius: $controlRadius;
        }
        &:last-child {
          border-bottom-right-radius: $controlRadius;
        }
      }
    }
  }
}

.Table--infoCell {
  text-align: center;
  padding: $spacing * 2 !important;

  .Loader {
    display: inline-flex;
  }
}