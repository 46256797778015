$breakpoints: (
  phoneSmall: 375px,
  phone: 450px,
  tablet: 768px,
  desktop: 960px,
  widescreen: 1152px,
  highdef: 1344px,
  ultrahd: 1920px,

);

$gameTileWidth: 178px;
$gameTileHeight: 100px;
$gameTileGap: 8px;

@import '@/styles/_globals';

.AdFallbackBanner {
  border-radius: $radius;
  padding: $marginSm3 $marginMd0;
  border: solid 1px #ccc;
  position: relative;
  width: 100%;
  height: 100%;
  padding-left: 300px;
  max-width: 736px;
  overflow: hidden;
  margin: auto;

  img {
    position: absolute;
    top: 50%;
    left: $marginMd0;
    transform: translateY(-50%);
    width: 271px;
    height: auto;
  }

  .Title {
    font-size: 12px;
    margin-bottom: $marginSm1;
  }

  p {
    font-size: 12px;
  }
  
  .Button {
    min-width: 110px;
    margin-left: $marginMd0;
  }
}