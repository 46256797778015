$breakpoints: (
  phoneSmall: 375px,
  phone: 450px,
  tablet: 768px,
  desktop: 960px,
  widescreen: 1152px,
  highdef: 1344px,
  ultrahd: 1920px,

);

$gameTileWidth: 178px;
$gameTileHeight: 100px;
$gameTileGap: 8px;

@import '@/styles/_globals';

.PlaywireAside {
  position: absolute;
  display: flex;
  flex-direction: column;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;

  > div:first-child {
    display: flex;
    margin: 0 auto;
  }

  > div:last-child {
    margin-top: auto;
    width: 332px;
    height: 250px;
  }

  > div > div {
    margin: auto;
    max-width: 332px;
    max-height: 100svh;
  }
}

.PlaywireAside__AdContainer {
  height: 0;
  width: 0;
  overflow: hidden;
}

.PlaywireAside--stickToLeft {
  right: auto;
  left: 0;

  > div:last-child {
    margin: 0 auto;
    margin-top: $spacing;
  }
}
