$breakpoints: (
  phoneSmall: 375px,
  phone: 450px,
  tablet: 768px,
  desktop: 960px,
  widescreen: 1152px,
  highdef: 1344px,
  ultrahd: 1920px,

);

$gameTileWidth: 178px;
$gameTileHeight: 100px;
$gameTileGap: 8px;

@import '@/styles/_globals';

$TileThumbnailWidth: 132px;
$TileThumbnailHeight: 88px;

$UpsellTileThumbnailWidth: 112px;
$UpsellTileThumbnailHeight: 74px;

.DailyArchiveByMonth,
.WeeklyArchive {
  .Title {
    text-align: center;
    font-size: 22px;
  }

  .Icon--small {
    width: 10px;
    height: 16px;
  }

  a[data-disabled=true] {
    // line-height: 16px;
    opacity: 0.35;
    pointer-events: none;
  }

  .Container__Inner {
    max-width: none;
  }
}

.ArchiveContainer {
  position: relative;
}

.ArchiveContainer__Inner {
  display: grid;
  gap: $spacing;
  grid-template-columns: repeat(2, 1fr);

  @media screen and (min-width: 500px) {
    grid-template-columns: repeat(3, 1fr);
  }

  @media screen and (min-width: #{$bodyMaxWidth}) {
    grid-template-columns: repeat(5, 1fr);
  }

  > .ArchiveTile {
    margin: 0 auto;
  }
}

.ArchiveTile {
  background: #a4a4a4;
  border-radius: $radius;
  padding: $marginSm1;
  display: flex;
  flex-direction: column;
  box-sizing: content-box;
  width: $TileThumbnailWidth;

  > span {
    display: block;
  }

  .ArchiveTile__MobileDate {
    text-align: center;
    margin-bottom: $marginSm2;
  
    @include isDesktop {
      display: none;
    }
  }

  .ArchiveStatusIcon {
    width: 2rem;
    height: 2rem;
  }

  &--state-locked,
  &--state-future {
    .ArchiveTile__Thumbnail img {
      filter: blur(2px) opacity(0.6);
    }
  }

  &--Upsell {
    padding: 0;
    width: $UpsellTileThumbnailWidth;

    .ArchiveTile__Thumbnail {
      height: $UpsellTileThumbnailHeight;
      width: $UpsellTileThumbnailWidth;

      +.ArchiveTile__Details {
        margin-top: 0;

        @include isMobileDevice {
          margin-top: $marginSm1;
        }
      }
    }

    .ArchiveTile__Details {
      span {
        font-size: 16px;
        padding: 0 !important;

        @include isMobileDevice {
          font-size: 12px;
        }
      }
    }
  }
}

.ArchiveTile__Thumbnail {
  background: #a4a4a4;
  
  border-radius: $radius * 0.5;
  overflow: hidden;

  height: $TileThumbnailHeight;

  +.ArchiveTile__Details {
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-top: $marginSm2;

    > span{
      &:first-child {
        display: block;
        text-align: center;
        padding-right: $marginSm2;
        margin-bottom: 0;
      }

      &:only-child {
        font-size: 16px;
      }

      + span {
        margin-left: auto;
        margin-top: 0;
      }
    }
  }
}

.ArchiveTile__Details {
  display: flex !important;
  flex-direction: column;
  white-space: nowrap;
  font-size: 70%;
  align-items: center;

  > span {
    display: block;
    text-align: center;

    @include isMobile {
      &:first-child {
        display: none !important;
      }

      &:last-child {
        margin-left: auto;
        margin-right: auto;
      }
    }

    &:first-child {
      margin-bottom: $marginSm2;
    }

    +span {
      flex: 0 0 auto;
    }
  }
}

.ArchiveTilePlaceholder {
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  width: 100%;
  box-sizing: border-box;
  border-radius: $radius;
  background-color: #DFDFDF;
  padding: $marginSm1;
  border: 1px solid #DFDFDF;

  .DatePlaceholder__Mobile {
    margin-bottom: $marginSm2;
  }

  @include isDesktop {
    font-size: 70%;

    .DatePlaceholder__Mobile {
      display: none;
    }
  }

  @include isMobile {
    .DatePlaceholder {
      display: none;
    }
  }

  .IconPlaceholder {
    height: 32px;
    margin-top: $marginSm2;
  }

  &__Image {
    width: $TileThumbnailWidth;
    height: $TileThumbnailHeight;
    background-color: #a4a4a4;
    border-radius: $radius * 0.5;
  }
}