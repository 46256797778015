$breakpoints: (
  phoneSmall: 375px,
  phone: 450px,
  tablet: 768px,
  desktop: 960px,
  widescreen: 1152px,
  highdef: 1344px,
  ultrahd: 1920px,

);

$gameTileWidth: 178px;
$gameTileHeight: 100px;
$gameTileGap: 8px;

@import '@/styles/_globals';

.ShareGameModal {

  .socialLinks {

    .Button {
      border: none;

      + .Button {
        margin-left: $spacing;

      }

    }

  }

  .copyButton {
    margin-left: $spacing;
  }
}