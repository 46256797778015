$breakpoints: (
  phoneSmall: 375px,
  phone: 450px,
  tablet: 768px,
  desktop: 960px,
  widescreen: 1152px,
  highdef: 1344px,
  ultrahd: 1920px,

);

$gameTileWidth: 178px;
$gameTileHeight: 100px;
$gameTileGap: 8px;

@import '@/styles/_globals';

.DailyArchive__Row {
  &:not(:first-child) {
    margin-top: $spacing;
  }

  > a {
    display: flex;
    flex-direction: column;
    font-size: 80%;
    align-items: center;
    padding: $marginSm2 $marginSm0;
    flex: 1;
    white-space: nowrap;
    border-radius: $radiusSm;

    em {
      margin-top: $marginSm1;

      + em {
        margin-top: $marginSm0;
      }
    }
    
    > .DailyArchive__Thumbnail {
      border-radius: 6px;
    }

    > * {
      display: block !important;
    }

    &.RouterLink {
      font-size: 85%;

      em:first-child {
        margin: 0 !important
      }
    }

    &.--today {
      > .DailyArchive__Thumbnail {
        border: solid 3px;
        box-sizing: content-box;
      }

      em {
        font-weight: bold;
      }
    }
  }
}

.DailyArchive__Spacer {
  width: 73px;
  min-height: 5.545rem;
}

.DailyArchive__Thumbnail {
  width: 272px * .3;
  height: 182px * .3;
  position: relative;

  > span {
    border-radius: 0.2rem !important;
  }

  > span:last-child {
    position: absolute !important;
    bottom: 0% !important;
    left: 50% !important;
    width: 272px !important;
    height: 182px !important;
    transform-origin: 50% 100%;
    transform: translateX(-50%) scale(0.3);
    max-width: none !important;
    transition: all 100ms ease !important;
    opacity: 0 !important;
    border-radius: $radius !important;
  }
}

.DailyArchive__Row a {
  &:hover {
    font-weight: bold;
    z-index: 10;

    .DailyArchive__Thumbnail {
      z-index: 1;

      > span:last-child {
        transform: translateX(-50%) scale(1);
        opacity: 1 !important;
      }
    }
  }
}

.DailyArchive__Thumbnail {
  background-image: url('/images/archiveThumbnailPlaceholder.png');
  background-size: cover;
  background-position: center;
  pointer-events: none;
  
  > * {
    opacity: 0 !important;
    transition: opacity 200ms ease !important;
  }

  &.--ready {
    > * { 
      opacity: 1 !important;
    }
  }
}
